<template>
  <div>
    <Nav active="surfaces" @add="addSurface" @save="save" :showSave="true" :isDirty="isDirty" :isSaving="isSaving" />
    <div class="table">
      <div class="table-header">
        <div class="table-header-item">Name</div>
        <div class="table-header-item">Web Name</div>
        <div class="table-header-item"></div>
        <div class="table-header-item"></div>
      </div>
      <div class="table-row" v-if="!isLoading.value" v-for="(surface, index) in surfaces">
        <div class="table-cell input-cell" :class="{ 'is-new': surface.isNew }">
          <span>{{ surface.name }}</span>
          <input type="text" v-model="surface.name" @input="markDirty(surface)" />
        </div>
        <div class="table-cell input-cell" :class="{ 'is-new': surface.isNew }">
          <span>{{ surface.webname }}</span>
          <select v-model="surface.webname" @change="markDirty(surface)">
            <option value="Smooth">Smooth</option>
            <option value="Cobbled">Cobbled</option>
            <option value="Textured">Textured</option>
            <option value="Rustic">Rustic</option>
          </select>
        </div>
        <div class="table-cell"></div>
        <div class="table-cell actions" @click="deleteSurface(surface.surfaceid, index)"><img src="../assets/trash.svg" /></div>
      </div>
      <div class="table-row" v-if="isLoading.value">
        <div class="table-cell">Loading...</div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from '../components/Nav.vue';
import useSurfacesQuery from '../composables/useSurfacesQuery';
import useSurfacesMutate from '../composables/useSurfacesMutate';

export default {
  name: 'Surfaces',
  components: {
    Nav,
  },
  setup() {
    const { surfaces, refetchSurfaces, isLoading } = useSurfacesQuery;
    const { updateSurfaces, deleteSurface } = useSurfacesMutate();
    return {
      surfaces,
      refetchSurfaces,
      isLoading,
      updateSurfaces,
      deleteSurface,
    };
  },
  data() {
    return {
      isSaving: false,
    };
  },
  methods: {
    addSurface() {
      this.surfaces.push({
        name: '',
        webname: '',
        surfaceid: null,
        isNew: true,
      });
    },
    markDirty(surface) {
      surface.isDirty = true;
    },
    async save() {
      const invalidSurface = this.surfaces.find((surface) => !surface.name || !surface.webname);
      if (invalidSurface) {
        alert('All fields must be filled out before saving.');
        return;
      }
      this.isSaving = true;

      const newAndDirty = this.surfaces.filter((surface) => surface.isNew || surface.isDirty);
      const success = await this.updateSurfaces(newAndDirty);
      if (success) {
        this.refetchSurfaces();
      }
      this.isSaving = false;
    },
    async deleteSurface(surfaceid, index) {
      if (!confirm('Are you sure you want to delete this surface?')) return;
      const surface = this.surfaces[index];

      if (surface.isNew) {
        this.surfaces.splice(index, 1);
      } else {
        const success = await this.deleteSurface(surface.surfaceid);
        if (success) {
          this.surfaces.splice(index, 1);
        }
      }
    },
  },
  computed: {
    isDirty() {
      return this.surfaces.some((surface) => surface.isDirty) || this.surfaces.some((surface) => surface.isNew);
    },
  },
  mounted() {
    this.refetchSurfaces();
  },
};
</script>

<style scoped lang="scss">
.table {
  & > div > div:nth-child(1) {
    width: 13%;
    min-width: 12rem;
  }

  & > div > div:nth-child(2) {
    width: 13%;
    min-width: 12rem;
  }

  & > div > div:nth-child(3) {
    flex: 1;
  }

  & > div > div:nth-child(4) {
    width: 4rem;
  }
}
</style>
